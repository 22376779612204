import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'

THREE.ColorManagement.enabled = false

/**
 * Base
 */
// Debug
const gui = new dat.GUI({ width: window.innerWidth - 30, title: 'COBAIN DEHH !!!!!!' })
gui.close()
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// textures
const textureLoader = new THREE.TextureLoader;
const particleTexture = textureLoader.load('/textures/8.png');

// particle
const snowGeo = new THREE.BufferGeometry();
const snowMat = new THREE.PointsMaterial({ color: 'white', size: 0.01, sizeAttenuation: true });
const count = 1000
const positions = new Float32Array(count * 3);
for (let i = 0; i < count * 3; i++) {
    positions[i] = (Math.random() - 0.5) * 20
}
snowGeo.setAttribute('position', new THREE.BufferAttribute(positions, 3));
const snow = new THREE.Points(snowGeo, snowMat);
scene.add(snow);

// galaxy
const parameters = {};
parameters.count = 15000;
parameters.size = 0.04
parameters.radius = 5;
parameters.branches = 6;
parameters.spin = 0.8;
parameters.randomness = 0.5;
parameters.randomnessPower = 3.2
parameters.insideColor = '#ff6030'
parameters.outsideColor = '#3463ad'

let particleGeo = null;
let particleMat = null;
let particle = null

const generateGalaxy = () => {
    if (particle) {
        particleGeo.dispose();
        particleMat.dispose();
        scene.remove(particle);
    }

    particleGeo = new THREE.BufferGeometry();
    particleMat = new THREE.PointsMaterial({
        // color: '#ff5588',
        size: parameters.size,
        sizeAttenuation: true,
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        map: particleTexture,
        vertexColors: true
    });

    const positions = new Float32Array(parameters.count * 3);
    const colors = new Float32Array(parameters.count * 3);

    const insideColor = new THREE.Color(parameters.insideColor);
    const outsideColor = new THREE.Color(parameters.outsideColor);

    for (let i = 0; i < parameters.count * 3; i++) {
        const i3 = i * 3;

        // positions
        const radius = Math.random() * parameters.radius;
        const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2;
        const spinAngle = radius * parameters.spin

        const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);
        const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);
        const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);

        positions[i3] = Math.cos(branchAngle + spinAngle) * radius + randomX;
        positions[i3 + 1] = 0 + randomY;
        positions[i3 + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ;

        // colors
        const mixedColor = insideColor.clone();
        mixedColor.lerp(outsideColor, radius / parameters.radius);
        colors[i3 + 0] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b
    }
    particleGeo.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    particleGeo.setAttribute('color', new THREE.BufferAttribute(colors, 3));

    particle = new THREE.Points(particleGeo, particleMat);
    scene.add(particle)
}

generateGalaxy();

gui.add(parameters, 'count').min(500).max(100000).step(1).onFinishChange(generateGalaxy).name('Jumlah Bintang')
gui.add(parameters, 'size').min(0.01).max(0.1).step(0.001).onFinishChange(generateGalaxy).name('Ukuran Bintang')
gui.add(parameters, 'radius').min(0.01).max(20).step(0.01).onFinishChange(generateGalaxy).name('Radius Galaxy')
gui.add(parameters, 'branches').min(2).max(20).step(1).onFinishChange(generateGalaxy).name('Jumlah Cabang')
gui.add(parameters, 'spin').min(-5).max(5).step(0.01).onFinishChange(generateGalaxy).name('Derajat Putaran')
// gui.add(parameters, 'randomness').min(0).max(1).step(0.01).onFinishChange(generateGalaxy)
gui.add(parameters, 'randomnessPower').min(1).max(10).step(0.01).onFinishChange(generateGalaxy).name('Seberapa random?')
gui.addColor(parameters, 'insideColor').min(1).onFinishChange(generateGalaxy).name('Warna Dalem')
gui.addColor(parameters, 'outsideColor').min(1).onFinishChange(generateGalaxy).name('Warna Luar')

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 7
camera.position.y = 4
camera.position.z = 9
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()